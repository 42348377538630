<template>
	<div id="sales_dep">
		<div class="main-body-filter">
			<div class="main-body-filter-left">
				<el-select v-model="filter.bigarea.value" :loading="filter.bigarea.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部区域" @change="selectFilter(1)">
					<el-option v-for="item in filter.bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
						:value="item.bg_id">
					</el-option>
				</el-select>
				<el-select v-model="filter.salesDep.value" :loading="filter.salesDep.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部售楼部" @change="selectFilter(2)">
					<el-option v-for="item in filter.salesDep.options" :key="item.sd_id" :label="item.sd_name"
						:value="item.sd_id">
					</el-option>
				</el-select>
				<el-input class="search-keywords filter-input" size="large" placeholder="请输入售楼部名称检索查询"
					prefix-icon="Search" v-model="filter.keywords" clearable @keyup.enter="inputFilter" >
				</el-input>
			</div>
		</div>
		<!-- 人脸集合内容 -->
		<div class="main-body-table">
			<SelectFilePrint :datas="table.tableFilterList" @selectClick="selectClick"></SelectFilePrint>
			<SelectTable id='facePrintBill' :tables="table.list" :confs="table.conf" :filters="table.tableFilterList" :styles="table.tableFilterList" :icons="['DeleteFilled']" :operate="true" @sizeChange="tablePageSize" @currentChange="tableChosePage" @alreadyRegistered="alreadyRegistered" @iconClick="iconClick"></SelectTable>
		</div>
		<!-- 已注册弹窗 -->
		<div class="main-body-dialog">
			<el-dialog v-model="ARtable.dialogVisible" destroy-on-close :title="ARtable.whichOne.fs_name" width="80%" align-center @closed="dialogClosed('AR')">
				<div class="dialog-already-registered" style="margin-top: -30px">
					<!-- 设备分组 -->
					<div class="choose-group-div">
						<el-menu
							:default-active="ARtable.activeIndex"
							class="el-menu-demo"
							mode="horizontal"
							@select="menuSelect"
						>
							<el-menu-item v-for="(item, index) in ARtable.menuList" :index="String(index)" :key="item+index">{{item.e_name}}</el-menu-item>
						</el-menu>
					</div>
					<!-- 搜索 -->
					<div class="search-div" style="margin: 10px 0; max-width: 860px; display: flex; flex-wrap: wrap; justify-content: space-between;">
						<el-input v-model="ARfilter.keywords" @change="ARSearch" size="large" placeholder="请输入人脸唯一标识" clearable style="width: 400px" />
						<el-date-picker
							v-model="ARfilter.startTime"
							type="date"
							placeholder="开始时间"
							size="large"
							@change="ARSearch"
						/>
						<el-date-picker
							v-model="ARfilter.endTime"
							type="date"
							placeholder="结束时间"
							size="large"
							@change="ARSearch"
						/>
					</div>
					<!-- 表格 -->
					<div class="table-div">
						<SelectFilePrint :datas="ARtable.tableFilterList"  @selectClick="ARSelectClick"></SelectFilePrint>
						<SelectTable id="ARPrintBill" :tables="ARtable.list" :confs="ARtable.conf" :filters="ARtable.tableFilterList" :icons="['RefreshRight']" :operate="true" @sizeChange="ARtablePageSize" @currentChange="ARtableChosePage" @mainToken="mainToken" @iconClick="iconClick"></SelectTable>
					</div>
				</div>
			</el-dialog>
		</div>
		<!-- 已注册弹窗内token弹窗 -->
		<div class="main-body-token-dialog">
			<el-dialog v-model="tokenTable.dialogVisible" v-if="tokenTable.dialogVisible" title="个人抓拍记录" width="70%" align-center @closed="dialogClosed('token')">
				<!-- 搜索 -->
				<div class="search-div" style="margin: 10px 0; max-width: 450px; display: flex; flex-wrap: wrap; justify-content: space-between;">
					<el-date-picker
						v-model="tokenFilter.startTime"
						type="date"
						placeholder="开始时间"
						size="large"
						@change="tokenSearch"
					/>
					<el-date-picker
						v-model="tokenFilter.endTime"
						type="date"
						placeholder="结束时间"
						size="large"
						@change="tokenSearch"
					/>
				</div>
				<!-- 表格 -->
				<div class="table-div">
					<SelectTable :tables="tokenTable.list" :confs="tokenTable.conf" :filters="tokenTable.columns" :icons="['RefreshRight']" :operate="true" @sizeChange="tokenTablePageSize" @currentChange="tokenTableChosePage" @iconClick="iconClick"></SelectTable>
				</div>
				</el-dialog>
		</div>
	</div>
</template>

<script setup>
	import axios from 'axios'
	import tableColumns from '@/assets/json/face/table/face-set.json'
	import CusTable from '@/components/common/CusTable.vue'
	import SelectFilePrint from '@/components/common/SelectFilePrint.vue'
	import SelectTable from '@/components/common/SelectTable.vue'
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import { exportToExcel } from "@/utils/out_excel.js"; // 导出表格excel
	import { exportToCsv } from "@/utils/out_csv.js"; // 导出表格csv
	import { exportToPrint } from "@/utils/out_print.js"; // 打印

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 弹窗关闭事件
	const dialogClosed = (val) => {
		if(val === 'AR') {
			ARtable.whichOne = {};
			ARtable.activeIndex= '0';
			ARtable.list= [];
			ARtable.conf.loadStatus= false;
			ARtable.conf.curPage= 1;
			ARtable.conf.pageLimit= 15;
			ARtable.conf.dataTotal = 0;
			ARtable.conf.emptyText = '';
			ARtable.menuList = [{'e_id': 0, 'e_name': "全部"}]
			ARfilter.keywords = '';
			ARfilter.startTime = '';
			ARfilter.endTime = '';
		} else if(val === 'token') {
			tokenTable.whichOne = {};
			tokenTable.list = [];
			tokenTable.conf.loadStatus = false;
			tokenTable.conf.curPage = 1;
			tokenTable.conf.pageLimit = 15;
			tokenTable.conf.dataTotal = 0;
			tokenTable.conf.emptyText = '';
			tokenFilter.startTime = '';
			tokenFilter.endTime = '';
		}
	}

	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		},
		salesDep: {
			options: [],
			value: '',
			is_load: false
		}
	});

	// 列表信息
	const table = reactive({
		list: [],
		columns: tableColumns.list,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 15,
			dataTotal: 0,
			sizes: [15, 50, 500, 3000],
			emptyText: ''
		},
		tableFilterList: tableColumns.list, // 筛选表格列列表
	})

	// 获取table列表
	const loadList = () => {
		// 清除现有table数据
		table.list = []
		table.conf.loadStatus = true
		table.conf.emptyText = '数据查询中...'
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_set.list.url, {
			bg_id: filter.bigarea.value,
			sd_id: filter.salesDep.value,
			keywords: filter.keywords,
			page: table.conf.curPage,
			limit: table.conf.pageLimit
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				table.conf.dataTotal = respon.Data.total
				table.list = respon.Data.list
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}

			table.conf.loadStatus = false
			table.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
		}).catch(error => {
			table.conf.loadStatus = false
			table.conf.emptyText = "服务器连接失败，请稍后重试"
		})
	}

	// 筛选框调用事件
	const selectFilter = (type) => {
		filter.keywords = ''
		table.conf.curPage = 1
		if(type == 1) {
			filter.salesDep.value = ''
			// 重新加载售楼部数据
			getSalesDepList()
		}
		// 重新加载table数据
		loadList()
	}

	// 人脸处理栏点击导出打印
	const selectClick = (type) => {
		if(type==="excel") {
			let headArr = []
			for (let index = 0; index < table.tableFilterList.length; index++) {
				let element = table.tableFilterList[index];
				if(element.show) {
					headArr.push(element.label)
				}
			}
			let smallArr = []
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = []
				let ele = table.list[index]
				for (let i = 0; i < table.tableFilterList.length; i++) {
					if(table.tableFilterList[i].show) {
						let e = table.tableFilterList[i].prop
						smallArr.push(ele[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToExcel(tableArr, headArr, "sheet1", "人脸集合");
		} else if(type==="csv") {
			let headObj = {}
			for (let index = 0; index < table.tableFilterList.length; index++) {
				let element = table.tableFilterList[index];
				if(element.show) {
					headObj[element.label] = element.label
				}
			}
			let smallArr = {}
			let tableArr = []
			for (let index = 0; index < table.list.length; index++) {
				smallArr = {}
				let ele = table.list[index]
				for (let i = 0; i < table.tableFilterList.length; i++) {
					if(table.tableFilterList[i].show) {
						let e = table.tableFilterList[i].prop
						smallArr[e] = (ele[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToCsv(tableArr, headObj, '人脸集合')
		} else if(type==='print') {
			exportToPrint('facePrintBill')
		}
	}

	// 输入框调用事件
	const inputFilter = () => {
		// 清空select选项框
		filter.salesDep.value = ''
		table.conf.curPage = 1
		// 重新加载售楼部数据
		if(filter.bigarea.value) {
			filter.bigarea.value = ''
			getSalesDepList()
		}
		// 重新加载table数据
		loadList()
	}

	// 设置table当前页码变化
	const tableChosePage = (curPage) => {
		// 存放当前页面
		table.conf.curPage = curPage
		// 重新加载table数据
		loadList()
	}

	// 设置table每页页码数
	const tablePageSize = (limit) => {
		table.conf.curPage = 1
		// 存放页码数
		table.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (table.conf.curPage === 1 || limit * table.conf.curPage <= table.conf.dataTotal) {
			// 重新加载table数据
			loadList()
		}
	}

	// 挂载
	const table_ref = ref(null)
	const add_sales_dep_ref = ref(null)
	
	// 获取售楼部
	const getSalesDepList = () => {
		ctx.$request_nl_.post(ctx.$api_.state.Face.sales_dep.list.url, {
			bg_id: filter.bigarea.value,
			page: 1,
			limit: 1000
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				filter.salesDep.options = respon.Data.list
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		})
	}

	// 已注册点击
	const alreadyRegistered = (item) => {
		ARtable.whichOne = item
		loadArMenuList()
		loadArTokenList('AR')
		ARtable.dialogVisible = true
	}

	// 操作点击
	const iconClick = (val, item) => {
		switch(val){
			case 'DeleteFilled':
				// 注销人脸合集
				ElMessageBox.confirm(
					'您确定要删除此数据?',
					'温馨提示', {
						confirmButtonText: '确认',
						cancelButtonText: '取消',
						type: 'warning',
				}).then(() => {
					// warn 开发时注释下列行，因为真的会删
					delteFace(item.fs_id)
				})
				break;
			case 'RefreshRight':
				// 重新加载人脸合集
				ElMessageBox.confirm(
					'您确定要重新加载此数据?',
					'温馨提示', {
						confirmButtonText: 'OK',
						cancelButtonText: '取消',
						type: 'warning',
				}).then(() => {
					reloadFace(item.img_arr)
				})
				break;
			default:
				break;
		}
	}

	// 注销人脸
	const delteFace = (id) => {
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_set.del.url, {
			fs_id: id
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				ctx.$message.success({
					message: respon.Message
				});
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		})
	}

	// 重新加载人脸信息
	const reloadFace = (arr) => {
		let sendAry = [
			axios.get(arr[0]+ '?restore'),
			axios.get(arr[1]+ '?restore'),
		];
		axios.all(sendAry).then(result => {
			ctx.$message.success({
				message: '资源已重新刷新'
			});
		}).catch(function (error) {
			ctx.$message.success({
				message: '资源已重新刷新'
			});
		});
	}

	// 已注册弹窗内信息
	const ARtable = reactive({
		whichOne: {}, // 是哪一个点击了已注册弹窗
		dialogVisible: false,
		activeIndex: '0',
		list: [],
		columns: tableColumns.ARList,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 15,
			dataTotal: 0,
			sizes: [15, 50, 500, 3000],
			emptyText: ''
		},
		tableFilterList: tableColumns.ARList, // 筛选表格列列表
		menuList: [{'e_id': 0, 'e_name': "全部"}]
	})

	// 已注册顶部筛选
	const ARfilter = reactive({
		keywords: '',
		startTime: '',
		endTime: ''
	});

	// 已注册搜索栏
	const ARSearch = () => {
		ARtable.conf.curPage = 1
		// 重新加载ARtable数据
		loadArTokenList('AR')
	}

	// 已注册处理栏点击导出打印
	const ARSelectClick = (type, val) => {
		if(type==="excel") {
			let headArr = []
			for (let index = 0; index < ARtable.tableFilterList.length; index++) {
				const element = ARtable.tableFilterList[index];
				if(element.show) {
					headArr.push(element.label)
				}
			}
			let smallArr = []
			let tableArr = []
			for (let index = 0; index < ARtable.list.length; index++) {
				smallArr = []
				let element = ARtable.list[index]
				for (let i = 0; i < ARtable.tableFilterList.length; i++) {
					if(ARtable.tableFilterList[i].show) {
						let e = ARtable.tableFilterList[i].prop
						smallArr.push(element[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToExcel(tableArr, headArr, "sheet1", ARtable.whichOne.fs_name+'已注册人脸');
		} else if(type==="csv") {
			let headObj = {}
			for (let index = 0; index < ARtable.tableFilterList.length; index++) {
				const element = ARtable.tableFilterList[index];
				if(element.show) {
					headObj[element.label] = element.label
				}
			}
			let smallArr = {}
			let tableArr = []
			for (let index = 0; index < ARtable.list.length; index++) {
				smallArr = {}
				let element = ARtable.list[index]
				for (let i = 0; i < ARtable.tableFilterList.length; i++) {
					if(ARtable.tableFilterList[i].show) {
						let e = ARtable.tableFilterList[i].prop
						smallArr[e] = (element[e])
					}
				}
				tableArr.push(smallArr)
			}
			exportToCsv(tableArr, headObj, ARtable.whichOne.fs_name+'已注册人脸')
		} else if(type==="print") {
			exportToPrint('ARPrintBill')
		}
	}

	// 获取 已注册/主token 弹窗内表格数据
	const loadArTokenList = (val) => {
		// 清除现有table数据
		let data = {}
		if(val === 'AR') {
			// 是AR表格
			ARtable.list = []
			ARtable.conf.loadStatus = true
			ARtable.conf.emptyText = '数据查询中...'
			data = {
				keywords: ARfilter.keywords,
				stime: ARfilter.startTime,
				etime: ARfilter.endTime,
				fs_id: ARtable.whichOne.fs_id,
				e_id: ARtable.menuList[ARtable.activeIndex].e_id,
				page: ARtable.conf.curPage,
				limit: ARtable.conf.pageLimit
			}
		} else if(val === 'token') {
			// 是token表格
			tokenTable.list = []
			tokenTable.conf.loadStatus = true
			tokenTable.conf.emptyText = '数据查询中...'
			data = {
				keywords: tokenTable.whichOne.fb_pid,
				stime: tokenFilter.startTime,
				etime: tokenFilter.endTime,
				fs_id: ARtable.whichOne.fs_id,
				e_id: ARtable.menuList[ARtable.activeIndex].e_id,
				page: tokenTable.conf.curPage,
				limit: tokenTable.conf.pageLimit
			}
		}
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_set.face_list.url, data)
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				if(val === 'AR') {
					ARtable.conf.dataTotal = respon.Data.total
					ARtable.list = respon.Data.list
				} else if(val === 'token') {
					tokenTable.conf.dataTotal = respon.Data.total
					tokenTable.list = respon.Data.list
				}
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}

			if(val === 'AR') {
				ARtable.conf.loadStatus = false
				ARtable.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			} else if(val === 'token') {
				tokenTable.conf.loadStatus = false
				tokenTable.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			}
		}).catch(error => {
			if(val === 'AR') {
				ARtable.conf.loadStatus = false
				ARtable.conf.emptyText = "服务器连接失败，请稍后重试"
			} else if(val === 'token') {
				tokenTable.conf.loadStatus = false
				tokenTable.conf.emptyText = "服务器连接失败，请稍后重试"
			}
		})
	}

	// 获取已注册内菜单列表
	const loadArMenuList = () => {
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_set.device.url, {
			fs_id: ARtable.whichOne.fs_id
		})
		.then((respon) => {
			if (respon.Code === ctx.$code_.state.success) {
				ARtable.menuList.push.apply(ARtable.menuList, respon.Data.list);
			} else {
				ctx.$message.error({
					message: respon.Message
				});
			}
		})
	}

	// 切换已注册内菜单列表
	const menuSelect = (index) => {
		ARtable.activeIndex = String(index)
		ARtable.conf.curPage = 1;
		loadArTokenList('AR');
	}

	// 设置ARtable当前页码变化
	const ARtableChosePage = (curPage) => {
		// 存放当前页面
		ARtable.conf.curPage = curPage
		// 重新加载ARtable数据
		loadArTokenList('AR')
	}

	// 设置ARtable每页页码数
	const ARtablePageSize = (limit) => {
		ARtable.conf.curPage = 1
		// 存放页码数
		ARtable.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (ARtable.conf.curPage === 1 || limit * ARtable.conf.curPage <= ARtable.conf.dataTotal) {
			// 重新加载ARtable数据
			loadArTokenList('AR')
		}
	}

	// 主token表格
	const tokenTable = reactive({
		whichOne: {}, // 是哪一个点击了已注册弹窗
		dialogVisible: false,
		list: [],
		columns: tableColumns.tokenList,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 15,
			dataTotal: 0,
			sizes: [15, 50, 500, 3000],
			emptyText: ''
		},
	})

	// 主token顶部筛选
	const tokenFilter = reactive({
		startTime: '',
		endTime: ''
	});

	// 主token搜索栏
	const tokenSearch = () => {
		tokenTable.conf.curPage = 1
		// 重新加载tokenTable数据
		loadArTokenList('token')
	}

	// 设置tokenTable当前页码变化
	const tokenTableChosePage = (curPage) => {
		// 存放当前页面
		tokenTable.conf.curPage = curPage
		// 重新加载tokenTable数据
		loadArTokenList('token')
	}

	// 设置tokenTable每页页码数
	const tokenTablePageSize = (limit) => {
		// 存放当前页面
		tokenTable.conf.curPage = 1
		// 存放页码数
		tokenTable.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (tokenTable.conf.curPage === 1 || limit * tokenTable.conf.curPage <= tokenTable.conf.dataTotal) {
			// 重新加载tokenTable数据
			loadArTokenList('token')
		}
	}

	// 打开主token弹窗
	const mainToken = (item) => {
		tokenTable.whichOne = item
		loadArTokenList('token')
		tokenTable.dialogVisible = true
	}

	onMounted(() => {
		// 初始化加载区域列表
		ctx.$pubFun_.getSelectCommonBigarea(filter.bigarea)
		getSalesDepList()
		// 初始化加载表格列表
		loadList()
	})
</script>